import { StatusDisplay } from "src/components/Devices/IndoorClimateStatus/IndoorClimateStatus"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { colorsV2 } from "src/ui/colors"
import AlmostFreezingIcon from "src/ui/icons/eventIcons/event-freezing.svg"
import NormalTemperatureIcon from "src/ui/icons/temperature.svg"
import HighTemperatureIcon from "src/ui/icons/temperature-high.svg"
import LowTemperatureIcon from "src/ui/icons/temperature-low.svg"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { convertTemperatureValue } from "src/utils/l10n"

export function TemperatureStatus({
  device,
  indoorClimateMonitoring,
}: {
  device: TDevice
  indoorClimateMonitoring?: TIndoorClimateMonitoring
}) {
  const user = useGetUser()
  const unit = getTemperatureUnitWithFallback(user)

  const latestTemperatureValue = device.latest_sensor_values?.temperature?.value
  const ongoingIndoorClimateAlerts = device.ongoing_indoor_climate_alerts || []
  const chargeStatus = device.charge_status
  const hideReading = chargeStatus === "connected_charging" || device.offline

  const temperatureToggleEnabled = indoorClimateMonitoring?.temperature_enabled

  const isAlmostFreezing =
    ongoingIndoorClimateAlerts.includes("almost_freezing")

  const isHighTemperatureEvent =
    ongoingIndoorClimateAlerts.includes("temperature_high")
  const isLowTemperatureEvent =
    ongoingIndoorClimateAlerts.includes("temperature_low")

  const formattedTemperatureReading = (
    <LocalizedTemperature value={latestTemperatureValue} />
  )

  const convertedTemperatureValue =
    latestTemperatureValue === undefined
      ? null
      : Number(convertTemperatureValue(latestTemperatureValue, unit))

  if (!temperatureToggleEnabled) {
    return <div></div>
  }

  if (hideReading) {
    return (
      <StatusDisplay
        color={colorsV2.textSecondary}
        reading={`-`}
        icon={NormalTemperatureIcon}
      />
    )
  }

  if (convertedTemperatureValue !== null && isAlmostFreezing) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={AlmostFreezingIcon}
      />
    )
  }

  if (convertedTemperatureValue !== null && isHighTemperatureEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={HighTemperatureIcon}
      />
    )
  }

  if (convertedTemperatureValue !== null && isLowTemperatureEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedTemperatureReading}
        icon={LowTemperatureIcon}
      />
    )
  }

  return (
    <StatusDisplay
      color={colorsV2.systemGoodDark}
      reading={formattedTemperatureReading}
      icon={NormalTemperatureIcon}
    />
  )
}
