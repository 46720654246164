import styled from "styled-components"

import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { colorsV2 } from "src/ui/colors"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TSize = "small" | "medium" | "large"
type BannerButton = {
  label: string
  onClick: () => void
  buttonProps?: TMButtonProps
}
export type TAlert =
  | "good"
  | "info"
  | "warning"
  | "neutral"
  | "error"
  | "default"

export function MBanner({
  type = "default",
  size = "medium",
  fullWidth = false,
  show = true,
  hideBorder,
  children,
  icon,
  title,
  buttons = [],
  ...props
}: {
  type?: TAlert
  size?: TSize
  fullWidth?: boolean
  show?: boolean
  hideBorder?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
  title?: React.ReactNode
  icon?: React.ReactNode
  buttons?: BannerButton[]
}) {
  if (!show) {
    return null
  }

  const AlertStyle = getAlertStyle(type)

  return (
    <AlertStyle
      size={size}
      $fullWidth={fullWidth}
      $hideBorder={hideBorder}
      {...props}
    >
      <ContentBox>
        {icon && <div>{icon}</div>}
        {title && (
          <MText variant="subtitle" color="unset">
            {title}
          </MText>
        )}
        {children && (
          <Body>
            <MText variant={getTextVariant(size)} color="unset">
              {children}
            </MText>
          </Body>
        )}
        {!!buttons.length && (
          <Buttons>
            {buttons.map(({ label, onClick, buttonProps }) => (
              <MButton
                key={label}
                variant="subtle"
                size="small"
                onClick={onClick}
                {...buttonProps}
              >
                {label}
              </MButton>
            ))}
          </Buttons>
        )}
      </ContentBox>
    </AlertStyle>
  )
}

const Body = styled.div`
  grid-column: span 2;
`

const Buttons = styled.div`
  display: flex;
  gap: ${spacing.S};
  flex-wrap: wrap;
  grid-column: span 2;
`

const ContentBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: ${spacing.S};
  row-gap: ${spacing.M};
`

function getAlertStyle(type: TAlert) {
  switch (type) {
    case "good":
      return Good
    case "info":
      return Info
    case "warning":
      return Warning
    case "error":
      return Error
    case "neutral":
      return Neutral
    default:
      return Default
  }
}

function getTextVariant(size: TSize): MTextProps["variant"] {
  switch (size) {
    case "small":
      return "bodyS"
    default:
      return "body"
  }
}

type TAlertStyle = {
  size?: TSize
  $fullWidth?: boolean
  $hideBorder?: boolean
}

const Base = styled.div<TAlertStyle>`
  background: ${colorsV2.neutral};
  ${({ size }) => {
    switch (size) {
      case "small":
        return `padding: 1px ${spacing.XS};`
      case "medium":
        return `padding: ${spacing.M};`
      default:
        return `padding: ${spacing.L};`
    }
  }}
  border-radius: ${(props) => (!props.$hideBorder ? "0.875rem" : "0")};
  display: ${(props) => (props.$fullWidth ? "block" : "inline-block")};
  flex-basis: ${(props) => (props.$fullWidth ? "100%" : "auto")};
`

const Default = styled(Base)`
  background: inherit;
  border-color: ${colorsV2.neutral};
  border-width: ${(props) => (!props.$hideBorder ? "1px" : "0px")};
  border-style: solid;
  color: inherit;
`

const Neutral = styled(Base)`
  color: ${colorsV2.textPrimary};
`

const Good = styled(Base)`
  background: ${colorsV2.systemGoodLight};
  color: ${colorsV2.systemGoodDark};
`

const Info = styled(Base)`
  background: ${colorsV2.systemInfoLight};
  color: ${colorsV2.systemInfoDark};
`

const Warning = styled(Base)`
  background: ${colorsV2.systemWarningLight};
  color: ${colorsV2.systemWarningDark};
`

const Error = styled(Base)`
  background: ${colorsV2.systemErrorLight};
  color: ${colorsV2.systemErrorDark};
`
