import styled from "styled-components"

import { cardBoxShadow } from "src/constants/shadows"
import { colorsV2 } from "src/ui/colors"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

type TCardPadding = "s" | "m" | "l"

export function MCardInteractive({
  showHoverEffect,
  linkTo,
  padding = "m",
  onClick,
  disabled,
  className,
  children,
}: {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  linkTo?: string
  showHoverEffect?: boolean
  padding?: TCardPadding
  disabled?: boolean
  className?: string
  children: React.ReactNode
}) {
  const _showHoverEffect = !!showHoverEffect || !!linkTo || !!onClick

  return (
    <Box
      $showHoverEffect={_showHoverEffect}
      $disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {linkTo ? (
        <StyledLink to={linkTo} underline="never">
          <Content $padding={padding}>{children}</Content>
        </StyledLink>
      ) : (
        <Content $padding={padding}>{children}</Content>
      )}
    </Box>
  )
}

const Box = styled.div<{
  $showHoverEffect?: boolean
  $disabled?: boolean
}>`
  border-radius: 16px;
  border: 1px solid ${colorsV2.divider};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  &:hover {
    ${({ $showHoverEffect }) => $showHoverEffect && cardBoxShadow};
  }
  cursor: ${({ $showHoverEffect, $disabled }) =>
    $showHoverEffect && !$disabled ? "pointer" : "unset"};
  transition: box-shadow 0.12s;
`

const Content = styled.div<{ $padding: TCardPadding }>`
  padding: ${({ $padding }) => {
    switch ($padding) {
      case "s":
        return spacing.M
      case "m":
        return spacing.L
      case "l":
        return spacing.XL
    }
  }};
`

// display:block and height/width styles are needed here to make sure the
// link-block occupies the whole space that the outer box takes up
const StyledLink = styled(InternalLink)`
  display: block;
  height: 100%;
  width: 100%;
`
