import styled from "styled-components"

import { HomeGroups } from "src/components/HomeGroups/HomeGroups"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

import { HomeList } from "./HomeList/HomeList"

export function Homes() {
  const { hash } = useAppLocation()
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const orgRole = org.user_role

  const { value: isHomegroupAdmin } = useIsHomeGroupAdmin()

  const hasAdminAccess = getAccessLogic({ role: orgRole }).hasAdminAccess

  const tabs: ITab[] = [
    {
      label: t(langKeys.home, { count: 2 }),
      id: Routes.Homes.location().hash ?? "rentalunits",
      view: <HomeList />,
    },
    {
      label: t(langKeys.organizations_home_group, { count: 2 }),
      id: Routes.HomeGroups.location().hash ?? "locations",
      view: <HomeGroups />,
      hidden: !(isHomegroupAdmin || hasAdminAccess),
    },
  ].filter((tab) => !tab.hidden)

  const tabIndex = tabs.findIndex((tab) => tab.id === hash.slice(1)) ?? 0

  return (
    <MainView title={t(langKeys.properties)}>
      <Tabs labels={tabs} tabIndex={tabIndex}>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.id}>{tab.view}</StyledTabPanel>
        ))}
      </Tabs>
    </MainView>
  )
}

const StyledTabPanel = styled(TabPanel)`
  padding-top: ${spacing.XL2};
`
