import { StatusDisplay } from "src/components/Devices/IndoorClimateStatus/IndoorClimateStatus"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { colorsV2 } from "src/ui/colors"
import MoldRiskIcon from "src/ui/icons/mold.svg"
import MoldRiskHighIcon from "src/ui/icons/mold-high.svg"

export function MoldRiskStatus({ device }: { device: TDevice }) {
  const { t, langKeys } = useTranslate()
  const chargeStatus = device.charge_status

  const hideReading = chargeStatus === "connected_charging" || device.offline
  const moldRiskLevel = device.mould_risk_level

  if (hideReading) {
    return (
      <StatusDisplay
        color={colorsV2.textSecondary}
        reading={`-`}
        icon={MoldRiskIcon}
      />
    )
  }

  if (moldRiskLevel === "ANALYZING") {
    return (
      <StatusDisplay
        color={colorsV2.textPrimary}
        reading={t(langKeys.risk_of_mold_analyzing)}
        icon={MoldRiskIcon}
      />
    )
  }

  if (moldRiskLevel === "LOW") {
    return (
      <StatusDisplay
        color={colorsV2.systemGoodDark}
        reading={t(langKeys.risk_of_mold_low)}
        icon={MoldRiskIcon}
      />
    )
  }

  if (moldRiskLevel === "MEDIUM") {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={t(langKeys.risk_of_mold_medium)}
        icon={MoldRiskHighIcon}
      />
    )
  }

  if (moldRiskLevel === "HIGH") {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={t(langKeys.risk_of_mold_high)}
        icon={MoldRiskHighIcon}
      />
    )
  }

  if (moldRiskLevel === "UNKNOWN") {
    return (
      <StatusDisplay
        color={colorsV2.textSecondary}
        reading={t(langKeys.risk_of_mold_unknown)}
        icon={MoldRiskIcon}
      />
    )
  }

  return (
    <StatusDisplay
      color={colorsV2.textSecondary}
      reading={`-`}
      icon={MoldRiskIcon}
    />
  )
}
