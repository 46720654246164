import { usePostTrackEventWithParams } from "./analyticsQueries"

type TSmokingDetectionEventTypes =
  | "Cigarette Feedback Link Clicked"
  | "Cigarette Report Findings Clicked"
  | "Cigarette Report Findings Initiated"
  | "Cigarette Report Findings Confirmed"

export type TCSDFeedbackContext = "feature_card" | "home" | "event_log"

type TCigaretteDetectionFeedbackProps = {
  type: "reported_events" | "unreported_events"
  context: TCSDFeedbackContext
}

function usePostSmokingDetectionAnalyticsEvent<
  TEventProperties = TCigaretteDetectionFeedbackProps,
>(event: TSmokingDetectionEventTypes) {
  return usePostTrackEventWithParams<
    TSmokingDetectionEventTypes,
    TEventProperties
  >({
    event,
  })
}

export function usePostSmokingDetectedClicked() {
  return usePostSmokingDetectionAnalyticsEvent<{
    type: "reported_events"
    context: "feature_card"
  }>("Cigarette Feedback Link Clicked")
}

export function usePostSmokingNotDetectedClicked() {
  return usePostSmokingDetectionAnalyticsEvent<{
    type: "unreported_events"
    context: "feature_card"
  }>("Cigarette Feedback Link Clicked")
}

function usePostCSDTrackingEvent(event: TSmokingDetectionEventTypes) {
  const csdHomeEvent = usePostSmokingDetectionAnalyticsEvent(event)
  return {
    ...csdHomeEvent,
    mutate: ({ context }: { context: TCSDFeedbackContext }) =>
      csdHomeEvent.mutate({ context, type: "reported_events" }),
  }
}

export function usePostCigaretteReportFindingsClicked() {
  return usePostCSDTrackingEvent("Cigarette Report Findings Clicked")
}

export function usePostCigaretteReportFindingsInitiated() {
  return usePostCSDTrackingEvent("Cigarette Report Findings Initiated")
}

export function usePostCigaretteReportFindingsConfirmed() {
  return usePostCSDTrackingEvent("Cigarette Report Findings Confirmed")
}
