import { components, paths } from "@minuthq/minut-api-types/schema"
import { CountryCode } from "libphonenumber-js/core"

import { TDevice } from "src/data/devices/types/deviceTypes"
import { Maybe } from "src/utils/tsUtil"

export type ILocation = Exclude<IHome["location"], undefined>

export type TCountryCode = CountryCode

export type Violations = Exclude<IHome["violations"], undefined>[number]

export const DEFAULT_OCCUPANCY_THRESHOLD = 0
export const DEFAULT_PROFILE_ID = ""
export const DEFAULT_CHECKIN_MESSAGE = ""
export const DEFAULT_HOME_ID = ""
export const DEFAULT_HOME_NAME = (homeId: string) => homeId

export type IGuestCommunication =
  components["schemas"]["HomeGuestCommunication"]
export interface IAddress {
  street_name1: string
  street_name2?: string
  post_code: string
  country: string
  city: string
  region?: string
  apartment_number?: string
  floor_number?: string
  notes?: string
}

export type IHome = components["schemas"]["Home"]

export interface IStatefulHome extends IHome {
  homeAlertState?: "warning" | "emergency" // This is a frontend state indicating the alert level of the home
  indoor_climate_monitoring?: TIndoorClimateMonitoring
}

export interface IEmergencyContact {
  name: string
  phone_number: string
}

export interface ISmokingDetection {
  /**
   * Will be set to true if smoking detection can be enabled on the home
   */
  available: boolean
  /**
   * The current state of smoking detection. Can be used to set the state
   */
  state: "enabled" | "disabled"
  /**
   * | Status | Meaning |
            |----|---|
            | undefined | If state is set to disabled this will be undefined|
            |alerting| Smoking has been detected|
            |idle| Smoking detection is enabled, and no smoke is detected|
   */
  status?: "alerting" | "idle"
  updated_at: string
  calibration_done_at?: string // ISO date string
}

export type IDisturbanceMonitoring =
  components["schemas"]["DisturbanceMonitoring"]

export type TDisturbanceMonitoringActive = NonNullable<
  IHome["disturbance_monitoring"]
>["disturbance_monitoring_active"]

export type TMaybeHome = Maybe<IHome>

export enum MonitoringType {
  MOTION = "MOTION",
  OCCUPANCY = "OCCUPANCY",
  SOUND = "SOUND",
  TEMPERATURE = "TEMPERATURE",
  HUMIDITY = "HUMIDITY",
  MOLD = "MOLD",
  FIRECO = "FIRE & CO",
  CIGARETTE = "CIGARETTE",
  INDOOR_CLIMATE = "INDOOR CLIMATE",
}

export interface IStatefulHomeWithDevices extends IStatefulHome {
  devices: TDevice[]
}

export type IHomeDisturbancePutBody =
  paths["/homes/{home_id}/disturbance"]["put"]["requestBody"]["content"]["application/json"]

export type IHomeDisturbancePutResponse =
  paths["/homes/{home_id}/disturbance"]["put"]["responses"]["200"]["content"]["application/json"]

export type TIndoorClimateMonitoring = NonNullable<
  IHome["indoor_climate_monitoring"]
>
