import { createContext, useMemo, useState } from "react"

import {
  isStoredFeatureEnabled,
  removeStoredFlag,
  storeFlag,
} from "src/components/Flags/flagContextUtil"
import {
  isDemoEnv,
  isParadiseProdEnv,
  isParadiseStagingEnv,
  REACT_APP_ALWAYS_SHOW_FLAGS,
} from "src/constants/env"
import { FeatureFlag } from "src/constants/featureFlags"
import { debug } from "src/utils/logger"

type TFlags = {
  [key in FeatureFlag]: boolean
}

export const FlagContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
  isEnabled: (
    s: FeatureFlag,
    options?: { localStorageOnly?: boolean }
  ): boolean => false,
  setFeature: (s: FeatureFlag, c: boolean) =>
    debug.log("provider not initialized"),
  flags: {} as TFlags,
  getOverride: (s: FeatureFlag) => undefined as TFlagOverride,
  /** `toggles` contains data that can be dynamically set after the FlagContext
   * has been loaded */
  get toggles() {
    return {
      get roles(): string[] {
        return []
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setRoles: (r: string[]) => {},
    }
  },
})

export function FlagProvider({ children }: { children: React.ReactNode }) {
  const [roles, setRoles] = useState<string[]>([])
  const [cache, setCache] = useState(initFlags())

  function isEnabled(
    featureName: FeatureFlag,
    options?: { localStorageOnly?: boolean }
  ): boolean {
    if (options?.localStorageOnly) {
      return isStoredFeatureEnabled(featureName) || false
    }
    const override = flagOverride(featureName, roles)
    const returnValue =
      flagOverride(featureName, roles)?.value ??
      cache.get(featureName) ??
      isStoredFeatureEnabled(featureName)
    return override?.enforced
      ? returnValue
      : (cache.get(featureName) ?? isStoredFeatureEnabled(featureName))
  }

  function enableFeature(featureName: FeatureFlag): void {
    debug.log("enabling", featureName)
    setCache((oldCache) => new Map(oldCache.set(featureName, true)))
    storeFlag(featureName, "true")
  }

  function disableFeature(featureName: FeatureFlag): void {
    debug.log("disabling", featureName)
    setCache((oldCache) => new Map(oldCache.set(featureName, false)))
    removeStoredFlag(featureName)
  }

  function setFeature(featureName: FeatureFlag, enable: boolean) {
    enable ? enableFeature(featureName) : disableFeature(featureName)
  }

  const flags = useMemo(() => {
    cache.forEach((v, k) => {
      const override = flagOverride(k, roles)
      const shouldEnforceOverride = override?.enforced
      cache.set(k, shouldEnforceOverride ? override.value || v : v)
    })

    // Casting necessary since Object.fromEntries doesn't preserve types
    return Object.fromEntries(cache) as TFlags
  }, [cache, roles])

  function reset() {
    setCache(initFlags())
  }

  const getOverride = (featureName: FeatureFlag) => {
    return flagOverride(featureName, roles)
  }

  return (
    <FlagContext.Provider
      value={{
        isEnabled,
        setFeature,
        flags,
        reset,
        getOverride,
        toggles: { roles, setRoles },
      }}
    >
      {children}
    </FlagContext.Provider>
  )
}

interface IFlagOverride {
  value: boolean | undefined
  reason: React.ReactNode
  flag: FeatureFlag
  enforced?: boolean
}
export type TFlagOverride = IFlagOverride | undefined

/** Used to enforce a flag's behavior regardless of what its stored state is. */
function flagOverride(flag: FeatureFlag, userRoles: string[]): TFlagOverride {
  switch (flag) {
    case FeatureFlag.SHOW_FLAGS_LINK:
      return {
        flag,
        value: REACT_APP_ALWAYS_SHOW_FLAGS,
        reason: <b>Force enabled by env var</b>,
      }

    case FeatureFlag.BETA:
      return isDemoEnv
        ? {
            flag,
            value: true,
            reason: <b>Beta forced ON for demo subdomain.</b>,
            enforced: true,
          }
        : undefined
    case FeatureFlag.OAUTH:
      return isParadiseProdEnv || isParadiseStagingEnv
        ? {
            flag,
            value: true,
            reason: <b>Default to true in paradise2.*.minut</b>,
          }
        : undefined

    default:
      return
  }
}

//#region Init helpers

function initFlags(): Map<FeatureFlag, boolean> {
  const urlFlags = initFlagsFromUrl()
  const flags: [FeatureFlag, boolean][] = []
  for (const value of Object.values(FeatureFlag)) {
    const override = flagOverride(value, [])

    if (urlFlags.includes(value)) {
      // The flag that comes from url is stored in localStorage so we can just use localStorage value
      flags.push([value, isStoredFeatureEnabled(value)])
    } else {
      flags.push([value, override?.value || isStoredFeatureEnabled(value)])
    }
  }
  return new Map(flags)
}

/** Check URL for feature flags and set them if found */
function initFlagsFromUrl() {
  const urlFlags: FeatureFlag[] = []
  try {
    const searchParams = new URLSearchParams(window.location.search)
    Object.values(FeatureFlag).forEach((feature) => {
      const value = searchParams.get(feature)
      if (value) {
        if (["off", "0", "false", "disabled"].includes(value.toLowerCase())) {
          debug.log("Deactivating", feature)
          removeStoredFlag(feature)
          urlFlags.push(feature)
        } else {
          debug.log("Activating", feature)
          storeFlag(feature, value)
          urlFlags.push(feature)
        }
      }
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(
      `Your browsers probably lacks support for the URLSearchParam API: ${err}. Any feature params won't be activated`
    )
  }
  return urlFlags
}
//#endregion
