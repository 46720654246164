import {
  TUpgradeBlockerContext,
  usePostPlanUpgradeClicked,
} from "src/data/analytics/queries/planUpgradeAnalyticsQueries"
import { usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent } from "src/data/analytics/queries/settingsAnalyticsQueries"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"

export type { TUpgradeBlockerContext } from "src/data/analytics/queries/planUpgradeAnalyticsQueries"

type UpgradeBlockerButtonProps = {
  context: TUpgradeBlockerContext | "call-assist"
  plan?: TPlan
  text?: string
  link?: string
}

export function UpgradeBlockerButton({
  context,
  plan,
  text,
  link,
  ...props
}: TMButtonProps & UpgradeBlockerButtonProps) {
  const { navigate } = useRouter()
  const postPlanUpgradeClicked = usePostPlanUpgradeClicked()
  const postCallAssistLearnMoreClicked =
    usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent()
  const { t, langKeys } = useTranslate()

  function handleClick() {
    if (context === "call-assist") {
      postCallAssistLearnMoreClicked.mutate({ context: "upgrade_popup" })
    } else {
      postPlanUpgradeClicked.mutate({ context })
    }
    navigate(link ?? Routes.ChangePlan.location({ plan }))
  }

  return (
    <MButton onClick={handleClick} {...props}>
      {text ?? t(langKeys.subscription_dialog_positive_action)}
    </MButton>
  )
}
