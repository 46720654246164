import { QueryClient, useQueryClient } from "@tanstack/react-query"

import {
  IFetchHomes,
  IHomeFilterBody,
} from "src/data/homes/types/homeQueryTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { ResponseServiceToggleAction } from "src/data/homes/types/responseServiceTypes"
import {
  IFetchOrganizations,
  IMembersFilter,
  IOrganization,
  IOrganizationGetParams,
} from "src/data/organizations/types/organizationTypes"
import { IPagingFilter } from "src/data/pagination/types/paginationTypes"

export const orgsKeys = {
  all() {
    return ["organization"] as const
  },

  orgLists() {
    return [...this.all(), "list"] as const
  },

  orgList(filter?: IOrganizationGetParams) {
    if (!filter) {
      return this.orgLists()
    }

    return [...this.orgLists(), filter] as const
  },

  orgNbr(filter: { userId: string | undefined }) {
    return [...this.orgLists(), "nbr", filter] as const
  },

  activeOrg(orgId?: string | null) {
    if (!orgId) {
      return [...this.all(), "active"] as const
    }
    return [...this.all(), "active", orgId] as const
  },

  org(orgId: string) {
    return [...this.all(), orgId] as const
  },

  membersList(orgId: string, filter?: IMembersFilter) {
    if (!filter) {
      return [...this.org(orgId), "members"] as const
    }
    return [...this.org(orgId), "members", filter] as const
  },

  invitations(orgId: string, filter?: IPagingFilter) {
    if (filter) {
      return [...this.org(orgId), "invitations", filter] as const
    }
    return [...this.org(orgId), "invitations"] as const
  },

  estimates(orgId: string) {
    return [...this.org(orgId), "estimates"]
  },

  createHomeEstimate(orgId: string) {
    return [...this.estimates(orgId), "createHome"]
  },

  orgHomes(orgId: string) {
    return [...this.org(orgId), "homes"] as const
  },
  /** Use to target all cached homes lists, ignoring filters, with fuzzy matching */
  homeLists(orgId: string) {
    return [...this.orgHomes(orgId), "list"] as const
  },
  /** Use to target cached homes lists, with applied filters */
  homeList(orgId: string, filter?: IHomeFilterBody) {
    if (!filter) {
      return [...this.homeLists(orgId)] as const
    }
    return [...this.homeLists(orgId), filter] as const
  },

  /** Use to target all cached home detail objects */
  homeDetails(orgId: string) {
    return [...this.orgHomes(orgId), "detail"] as const
  },
  /** Use to target specific cached home detail object, by id */
  homeDetail(orgId: string, homeId: string) {
    return [...this.homeDetails(orgId), homeId] as const
  },
  homeMembers(orgId: string, homeId: string, filter?: IPagingFilter) {
    if (!filter) {
      return [...this.homeDetail(orgId, homeId), "members"] as const
    }

    return [...this.homeDetail(orgId, homeId), "members", filter] as const
  },

  /** Use to target response service homes */
  responseServiceHomes(orgId: string, filters?: IHomeFilterBody) {
    if (!filters) return [...this.homeLists(orgId), "MRP"] as const
    return [...this.homeLists(orgId), "MRP", filters] as const
  },

  /** Use to target response service providers connected to homeids */
  providerHomes(
    orgId: string,
    homeIds: string[],
    filter?: { address?: string }
  ) {
    if (!filter) {
      return [...this.org(orgId), "providers", homeIds] as const
    }
    return [...this.org(orgId), "providers", homeIds, filter] as const
  },

  /** Use to target specific cached home price, by id */
  nextAdditionalCost(homeId: string) {
    return ["nextAdditionalCost", homeId] as const
  },

  /** Use to target specific cached home shortcodes, by id */
  shortcodes(orgId: string, homeId: string) {
    return [...this.orgHomes(orgId), "shortcodes", homeId] as const
  },

  /** Use to target specific cached home log events, by id */
  homeEvents(orgId: string, homeId: string, filter?: IHomeFilterBody) {
    return [...this.orgHomes(orgId), "events", homeId, filter] as const
  },

  /** Use to target response service toggle */
  responseServiceToggleEstimate(
    homeId: string,
    toggleAction: ResponseServiceToggleAction
  ) {
    return ["responseServiceToggleEstimate", homeId, toggleAction] as const
  },

  /** Use to target response service callouts estimates */
  responseServiceCalloutEstimates() {
    return ["responseServiceCalloutEstimate"] as const
  },

  /** Use to target response service providers */
  responseServiceProviders() {
    return ["responseServiceProvider", "providers"] as const
  },

  /** Use to target response service provider */
  responseServiceProvider(providerId: string) {
    return [...this.responseServiceProviders(), providerId] as const
  },
} as const

export function useOrgQueryCache() {
  const queryClient = useQueryClient()

  /**
   * Update organization homes cache with new data, e.g., after a PATCH.
   *
   * @param orgId: The organization id
   * @param updater: Updater function which to modify organization homes in cache
   * @param appliedFilters: Pass this arg if you want to precicely target a
   * filtered homes list; the default is to iterate through all home list objects
   * in the cache which might be slow if the user has gone through several pages
   * of homes.
   */
  function updateCachedHomeList(
    orgId: string,
    updater: (homes: IHome[]) => IHome[],
    appliedFilters?: IHomeFilterBody
  ) {
    const cacheKey = orgsKeys.homeList(orgId, appliedFilters)

    queryClient.setQueriesData<IFetchHomes | undefined>(cacheKey, (cache) => {
      if (!cache) return undefined

      const { homes: cachedHomes = [] } = cache

      return { ...cache, homes: updater(cachedHomes) }
    })
  }

  /**
   * Update organization home detail cache with new data, e.g., after a PATCH.
   *
   * @param orgId: The organization id
   * @param updater: Updater function which to modify organization homes in cache
   * @param appliedFilters: Pass this arg if you want to precicely target a
   * filtered homes list; the default is to iterate through all home list objects
   * in the cache which might be slow if the user has gone through several pages
   * of homes.
   */
  function updateCachedHomeDetail(
    orgId: string,
    homeId: string,
    updater: (cache: IHome) => IHome
  ) {
    const cacheKey = orgsKeys.homeDetail(orgId, homeId)

    queryClient.setQueryData<IHome | undefined>(cacheKey, (cachedHome) => {
      if (!cachedHome) return undefined

      return updater(cachedHome)
    })
  }

  function updateCachedOrgList(
    updater: (cache: IFetchOrganizations) => IFetchOrganizations,
    filter?: IOrganizationGetParams
  ) {
    const cachedKey = orgsKeys.orgList(filter)

    queryClient.setQueryData<IFetchOrganizations>(cachedKey, (cache) => {
      if (!cache) return undefined

      return updater(cache)
    })
  }

  function updateCachedOrgDetail(
    orgId: string,
    updater: (cache: IOrganization) => IOrganization
  ) {
    const cacheKey = orgsKeys.org(orgId)

    queryClient.setQueryData<IOrganization>(cacheKey, (cachedOrg) => {
      if (!cachedOrg) return undefined

      return updater(cachedOrg)
    })

    queryClient.setQueriesData<IOrganization>(orgsKeys.activeOrg(), (o) => {
      if (!o || o.id !== orgId) return
      return updater(o)
    })
  }

  function removeCachedOrg(orgId: string) {
    queryClient.invalidateQueries(orgsKeys.orgList())

    const cacheKey = orgsKeys.org(orgId)
    // Remove cached org detail
    queryClient.removeQueries(cacheKey)
    queryClient.removeQueries(orgsKeys.activeOrg())

    // Remove cached org from list
    queryClient.setQueriesData<IFetchOrganizations | undefined>(
      orgsKeys.orgList(),
      (cache) => {
        if (!cache) return undefined

        const { organizations: cachedOrgs = [], paging } = cache
        const transformedOrgs = cachedOrgs?.filter((cachedOrg) => {
          return cachedOrg.id !== orgId
        })
        return { organizations: transformedOrgs, paging }
      }
    )
  }

  return {
    updateCachedHomeList,
    updateCachedHomeDetail,
    updateCachedOrgList,
    updateCachedOrgDetail,
    removeCachedOrg,
  }
}

/**
 * Remove home from cache, e.g., after a DELETE mutation.
 *
 * @param queryClient: A reference to the query client that accesses the cache
 */
export function removeCachedHome(
  queryClient: QueryClient,
  orgId: string,
  homeId: string
) {
  // Remove cached home detail
  queryClient.removeQueries(orgsKeys.homeDetail(orgId, homeId))

  // Remove cached home form list
  queryClient.setQueriesData<IFetchHomes | undefined>(
    orgsKeys.homeLists(orgId),
    (cache) => {
      if (!cache) return undefined

      const { homes: cachedHomes = [], paging } = cache
      const transformedHomes = cachedHomes?.filter((cachedHome) => {
        return cachedHome?.home_id !== homeId
      })
      return { homes: transformedHomes, paging }
    }
  )
}
