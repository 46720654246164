import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { divider } from "src/ui/colors"
import { TDefaultBoxWidth } from "src/ui/Layout/DefaultBox"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface ITitleBarProps {
  title: React.ReactNode
  description?: React.ReactNode
  actionBar?: React.ReactNode
  children?: React.ReactNode
  showBottomBorder?: boolean
  bottomMargin?: "none" | "small" | "medium"
  breadcrumbs?: React.ReactNode
  size?: TDefaultBoxWidth
  titleVariant?: MTextProps["variant"]
}

export function Titlebar({
  title,
  description,
  actionBar,
  children,
  showBottomBorder,
  breadcrumbs,
  bottomMargin = "medium",
  size = "medium",
  titleVariant = "heading1",
}: ITitleBarProps) {
  return (
    <TitleBar
      $showBottomBorder={!!showBottomBorder}
      $bottomMargin={bottomMargin}
      size={size}
    >
      <LeftBox>
        {breadcrumbs}
        <TitleText>
          <StyledHeading variant={titleVariant}>{title}</StyledHeading>
          {description && <MText variant="body">{description}</MText>}
        </TitleText>
      </LeftBox>
      {actionBar}
      {children}
    </TitleBar>
  )
}

const LeftBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const TitleBar = styled.div<{
  $showBottomBorder: boolean
  $bottomMargin: "none" | "small" | "medium"
  size: TDefaultBoxWidth
}>`
  display: grid;
  align-items: start;
  gap: ${spacing.M};

  button {
    flex: 0 0 auto;
  }

  border-bottom: ${({ $showBottomBorder }) =>
    !$showBottomBorder ? "none" : `1px solid ${divider}`};
  padding-bottom: ${({ $showBottomBorder: showBottomBorder }) =>
    !showBottomBorder ? "unset" : `${spacing.XL}`};

  margin-bottom: ${({ $bottomMargin }) => {
    switch ($bottomMargin) {
      case "none":
        return 0
      case "small":
        return spacing.L
      default:
        return spacing.XL2
    }
  }};

  max-width: ${(props) => {
    switch (props.size) {
      case "small":
        return "700px"
      case "medium":
        return "940px"
      case "large":
        return "1300px"
      default:
        return "unset"
    }
  }};

  @container (${breakpoint.smallUp}) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`

const TitleText = styled.div`
  display: grid;
  gap: ${spacing.M};
  max-width: 80ch;
`

const StyledHeading = styled(MText)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
