import { StatusDisplay } from "src/components/Devices/IndoorClimateStatus/IndoorClimateStatus"
import { getSensorValue } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { colorsV2 } from "src/ui/colors"
import NormalHumidityIcon from "src/ui/icons/humidity.svg"
import HighHumidityIcon from "src/ui/icons/humidity-high.svg"
import LowHumidityIcon from "src/ui/icons/humidity-low.svg"

export function HumidityStatus({
  device,
  indoorClimateMonitoring,
}: {
  device: TDevice
  indoorClimateMonitoring?: TIndoorClimateMonitoring
}) {
  const latestHumidityValue = getSensorValue(
    device.latest_sensor_values?.humidity
  )
  const ongoingIndoorClimateAlerts = device.ongoing_indoor_climate_alerts || []
  const chargeStatus = device.charge_status
  const hideReading = chargeStatus === "connected_charging" || device.offline

  const humidityToggleEnabled = indoorClimateMonitoring?.humidity_enabled

  const isHighHumidityEvent =
    ongoingIndoorClimateAlerts.includes("humidity_high")
  const isLowHumidityEvent = ongoingIndoorClimateAlerts.includes("humidity_low")

  const formattedHumidityReading =
    chargeStatus === "connected_charging" ? "-" : `${latestHumidityValue} %`

  const convertedHumidityValue = !!device.latest_sensor_values?.humidity?.value
    ? Number(latestHumidityValue)
    : null

  if (!humidityToggleEnabled) {
    return <div></div>
  }

  if (hideReading) {
    return (
      <StatusDisplay
        color={colorsV2.textSecondary}
        reading={`-`}
        icon={NormalHumidityIcon}
      />
    )
  }

  if (convertedHumidityValue !== null && isHighHumidityEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedHumidityReading}
        icon={HighHumidityIcon}
      />
    )
  }

  if (convertedHumidityValue !== null && isLowHumidityEvent) {
    return (
      <StatusDisplay
        color={colorsV2.systemErrorDark}
        reading={formattedHumidityReading}
        icon={LowHumidityIcon}
      />
    )
  }

  return (
    <StatusDisplay
      color={colorsV2.systemGoodDark}
      reading={formattedHumidityReading}
      icon={NormalHumidityIcon}
    />
  )
}
