import styled from "styled-components"

import { DocumentHead } from "src/router/DocumentHead"
import { DefaultBox, TDefaultBoxWidth } from "src/ui/Layout/DefaultBox"
import { ITitleBarProps, Titlebar } from "src/ui/Layout/Titlebar"
import { MTextProps } from "src/ui/MText"
import { isString } from "src/utils/genericUtil"

/**
 * Layout component for app 'main views'.
 *
 * The `metaTitle` prop is only necessary if either of the following are true:
 * 1. The title prop is not a string type and you want to give the view a
 *    non-default page title.
 * 2. You want the title and meta title to be different.
 */
export function MainView({
  children,
  size = "large",
  banner,
  metaTitle,
  title,
  titleBarProps,
  breadcrumbs,
  titleVariant = "heading1",
}: {
  title: React.ReactNode
  children: React.ReactNode
  metaTitle?: string
  size?: TDefaultBoxWidth
  banner?: React.ReactNode
  breadcrumbs?: React.ReactNode
  titleBarProps?: Omit<ITitleBarProps, "size" | "title">
  titleVariant?: MTextProps["variant"]
}) {
  const _metaTitle = !!metaTitle
    ? metaTitle
    : isString(title)
      ? title
      : undefined

  return (
    <MainViewBox>
      <DocumentHead title={_metaTitle} />

      {banner}

      <Titlebar
        title={title}
        size={size}
        breadcrumbs={breadcrumbs}
        titleVariant={titleVariant}
        {...titleBarProps}
      />

      <DefaultBox size={size}>{children}</DefaultBox>
    </MainViewBox>
  )
}

const MainViewBox = styled.div`
  container-type: inline-size;
`
