import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { AlarmMode, AlarmStatus, IAlarm } from "src/data/homes/types/alarmTypes"
import {
  IFetchHomes,
  IHomeFilterBody,
  IPatchHome,
  IPostHome,
} from "src/data/homes/types/homeQueryTypes"
import {
  IDisturbanceMonitoring,
  IHome,
  IHomeDisturbancePutBody,
  IHomeDisturbancePutResponse,
  ISmokingDetection,
  TIndoorClimateMonitoring,
} from "src/data/homes/types/homeTypes"
import {
  orgsKeys,
  removeCachedHome,
  useOrgQueryCache,
} from "src/data/organizations/queries/organizationQueryCache"
import {
  HomeRole,
  IHomeMember,
  IOrganizationMember,
} from "src/data/organizations/types/organizationMemberTypes"
import {
  IBasePaginatedEndpoint,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const API_ORGS = `${API_DEFAULT}/organizations`

interface IFetchHomeQuery {
  orgId: string
  homeId: string
  filter?: IPagingFilter
}
/**
 * Returns a home by id from the given organization.
 *
 * https://my.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations~1{organization_id}~1homes~1{home_id}/get
 */
export function useFetchHome({
  orgId,
  homeId,
  options,
}: IFetchHomeQuery & {
  options?: UseQueryOptions<
    IHome,
    AxiosError,
    IHome,
    ReturnType<typeof orgsKeys.homeDetail>
  >
}) {
  async function fetchHome({ orgId, homeId }: IFetchHomeQuery): Promise<IHome> {
    const response = await minutApiHttpClient.get<IHome | { home: IHome }>(
      `${API_ORGS}/${orgId}/homes/${homeId}`
    )
    return getHomeFromResponse(response.data)
  }

  return useQuery(
    orgsKeys.homeDetail(orgId, homeId),
    () => fetchHome({ orgId, homeId }),
    {
      keepPreviousData: true,
      ...options,
      enabled:
        options?.enabled !== undefined
          ? options.enabled && !!orgId && !!homeId
          : !!orgId && !!homeId,
    }
  )
}

/**
 * Returns a list of all homes belonging by the given organization.
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations~1{organization_id}~1homes/get
 *
 */

export async function fetchHomes({
  orgId,
  filters,
}: {
  orgId: string
  filters: IHomeFilterBody
}): Promise<IFetchHomes> {
  if (filters.name !== undefined && filters.name.length < 1) {
    delete filters.name
  }
  if (filters.limit === 0) {
    filters.limit = HOME_LIST_LIMIT
  }

  const response = await minutApiHttpClient.get<IFetchHomes>(
    `${API_ORGS}/${orgId}/homes`,
    {
      params: filters,
    }
  )

  return response.data
}

export function useFetchHomes<TQueryFnData = IFetchHomes>({
  orgId,
  ...props
}: {
  orgId: string
  filters: IHomeFilterBody
  options?: UseQueryOptions<
    IFetchHomes,
    AxiosError,
    TQueryFnData,
    ReturnType<typeof orgsKeys.homeList>
  >
}) {
  return useQuery(
    orgsKeys.homeList(orgId, props.filters),
    () => fetchHomes({ orgId, filters: { sort: "name", ...props.filters } }),
    {
      keepPreviousData: true,
      ...props?.options,
    }
  )
}

/** Fetch the number of homes in an organization */
export function useFetchHomesCount({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: IHomeFilterBody
  options?: UseQueryOptions<
    IFetchHomes,
    AxiosError,
    number,
    ReturnType<typeof orgsKeys.homeList>
  >
}) {
  return useFetchHomes<number>({
    orgId,
    filters: { ...filter, limit: 1 },
    options: {
      staleTime: 1000,
      ...options,
      // https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
      select: (data) => data.paging.total_count,
    },
  })
}

interface IPostHomeMutation {
  orgId: string
  body: IPostHome
}
export function usePostHome<ErrorType>() {
  const cache = useQueryClient()

  async function postHome({
    orgId,
    body,
  }: {
    orgId: string
    body: IPostHome
  }): Promise<IHome> {
    const response = await minutApiHttpClient.post<IHome | { home: IHome }>(
      `${API_ORGS}/${orgId}/homes`,
      body
    )
    return getHomeFromResponse(response.data)
  }

  return useMutation<IHome, AxiosError<ErrorType>, IPostHomeMutation>(
    postHome,
    {
      onSuccess: (newHome, variables) => {
        cache.invalidateQueries(orgsKeys.homeLists(variables.orgId))
        // We need to refetch the active organization so the `subscription_quantity_limit_reached` on the organization gets updated
        cache.invalidateQueries(orgsKeys.activeOrg())
      },
    }
  )
}

interface IPatchHomeMutation {
  orgId: string
  homeId: string
  data: IPatchHome
}
export function usePatchHome<ErrorType>() {
  const queryClient = useQueryClient()
  const { updateCachedHomeDetail, updateCachedHomeList } = useOrgQueryCache()

  async function patchHome({
    orgId,
    homeId,
    data,
  }: IPatchHomeMutation): Promise<IHome> {
    const response = await minutApiHttpClient.patch<IHome | { home: IHome }>(
      `${API_ORGS}/${orgId}/homes/${homeId}`,
      data
    )
    return getHomeFromResponse(response.data)
  }

  return useMutation<IHome, AxiosError<ErrorType>, IPatchHomeMutation>(
    patchHome,
    {
      onSuccess: (home: IHome, vars) => {
        queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
        updateCachedHomeDetail(vars.orgId, vars.homeId, () => home)
        updateCachedHomeList(vars.orgId, (homes) => {
          return homes.map((h) => {
            // Use a map to retain order of list
            if (h.home_id === home.home_id) {
              return home
            } else {
              return h
            }
          })
        })
        // If we run into performance issues wrt to not precicely targeting
        // filtered lists, consider removing updateCachedHomeList and having
        // callers keep track of when to update the list
      },
    }
  )
}

interface IPatchHomeDisturbanceMonitoring {
  orgId: string
  homeId: string
  data: { active: boolean }
}
export function usePatchHomeDisturbanceMonitoring<ErrorType>() {
  const queryClient = useQueryClient()
  const { updateCachedHomeList, updateCachedHomeDetail } = useOrgQueryCache()

  async function patchHomeDisturbanceMonitoring({
    orgId,
    homeId,
    data,
  }: IPatchHomeDisturbanceMonitoring): Promise<IDisturbanceMonitoring> {
    const response = await minutApiHttpClient.patch<IDisturbanceMonitoring>(
      `${API_DEFAULT}/homes/${homeId}/disturbance_monitoring`,
      data
    )
    return response.data
  }

  return useMutation<
    IDisturbanceMonitoring,
    AxiosError<ErrorType>,
    IPatchHomeDisturbanceMonitoring
  >(patchHomeDisturbanceMonitoring, {
    onSuccess: (disturbance_monitoring, vars) => {
      updateCachedHomeList(vars.orgId, (homes) => {
        const newHomes = homes.map((home) => {
          if (home.home_id === vars.homeId) {
            return {
              ...home,
              disturbance_monitoring: {
                ...disturbance_monitoring,
                disturbance_monitoring_active:
                  home.disturbance_monitoring?.disturbance_monitoring_active ||
                  true,
              },
            }
          }

          return home
        })

        return newHomes
      })

      updateCachedHomeDetail(vars.orgId, vars.homeId, (cachedHome) => {
        return {
          ...cachedHome,
          disturbance_monitoring: {
            ...disturbance_monitoring,
            disturbance_monitoring_active:
              cachedHome.disturbance_monitoring
                ?.disturbance_monitoring_active || true,
          },
        }
      })
      // Invalidate queries to trigger a refetch in the background while also performing an optimistic update of the cache
      queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
      queryClient.invalidateQueries(
        orgsKeys.homeDetail(vars.orgId, vars.homeId)
      )
    },
  })
}

export function usePutHomeDisturbance() {
  async function putHomeDisturbance({
    orgId,
    homeId,
    body,
  }: {
    orgId: string
    homeId: string
    body: IHomeDisturbancePutBody
  }) {
    const response = await minutApiHttpClient.put<IHomeDisturbancePutResponse>(
      `${API_DEFAULT}/homes/${homeId}/disturbance`,
      body
    )
    return response.data
  }

  return useMutation(putHomeDisturbance)
}

interface IPatchHomeSecurityAlarm {
  orgId: string
  homeId: string
  data: {
    alarm_status?: AlarmStatus
    alarm_mode?: AlarmMode
    silent_alarm?: boolean
    scheduled_alarm_active?: boolean
  }
}
export function usePatchHomeSecurityAlarm<ErrorType>() {
  const queryClient = useQueryClient()
  const { updateCachedHomeList, updateCachedHomeDetail } = useOrgQueryCache()

  async function patchHomeSecurityAlarm({
    orgId,
    homeId,
    data,
  }: IPatchHomeSecurityAlarm): Promise<IAlarm> {
    const response = await minutApiHttpClient.patch<IAlarm>(
      `${API_DEFAULT}/homes/${homeId}/alarm`,
      data
    )
    return response.data
  }

  return useMutation<IAlarm, AxiosError<ErrorType>, IPatchHomeSecurityAlarm>(
    patchHomeSecurityAlarm,
    {
      onSuccess: (newAlarmInfo, vars) => {
        updateCachedHomeList(vars.orgId, (homes) => {
          const newHomes = homes.map((home) => {
            if (home.home_id === vars.homeId) {
              return { ...home, alarm: newAlarmInfo }
            }

            return home
          })
          return newHomes
        })

        updateCachedHomeDetail(vars.orgId, vars.homeId, (cachedHome) => {
          return { ...cachedHome, alarm: newAlarmInfo }
        })
        // Invalidate queries to trigger a refetch in the background while also performing an optimistic update of the cache
        queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
        queryClient.invalidateQueries(
          orgsKeys.homeDetail(vars.orgId, vars.homeId)
        )
      },
    }
  )
}

interface IPatchSmokingDetection {
  homeId: string
  data: Partial<Pick<ISmokingDetection, "state" | "status">>
}
export function usePatchSmokingDetection({ orgId }: { orgId: string }) {
  const { updateCachedHomeDetail, updateCachedHomeList } = useOrgQueryCache()

  async function patchSmokingDetection({
    homeId,
    data,
  }: IPatchSmokingDetection): Promise<IHome["smoking_detection"]> {
    const response = await minutApiHttpClient.patch<IHome["smoking_detection"]>(
      `${API_DEFAULT}/homes/${homeId}/smoking_detection`,
      data
    )
    return response.data
  }

  return useMutation<
    IHome["smoking_detection"],
    AxiosError,
    IPatchSmokingDetection
  >(patchSmokingDetection, {
    onSuccess: (smokingDetection: IHome["smoking_detection"], vars) => {
      updateCachedHomeDetail(orgId, vars.homeId, (home) => ({
        ...home,
        smoking_detection: smokingDetection,
      }))
      updateCachedHomeList(orgId, (homes) => {
        return homes.map((home) => {
          // Use a map to retain order of list
          if (home.home_id === vars.homeId) {
            return { ...home, smoking_detection: smokingDetection }
          } else {
            return home
          }
        })
      })
      // If we run into performance issues wrt to not precicely targeting
      // filtered lists, consider removing updateCachedHomeList and having
      // callers keep track of when to update the list
    },
  })
}

interface IDeleteHomeMutation {
  orgId: string
  homeId: string
}
export function useDeleteHome<ErrorType = { 401: "Not allowed" }>() {
  const queryClient = useQueryClient()

  async function deleteHome({ orgId, homeId }: IDeleteHomeMutation) {
    return await minutApiHttpClient.delete(
      `${API_ORGS}/${orgId}/homes/${homeId}`
    )
  }

  return useMutation<AxiosResponse, AxiosError<ErrorType>, IDeleteHomeMutation>(
    deleteHome,
    {
      onSuccess: (_, vars) => {
        removeCachedHome(queryClient, vars.orgId, vars.homeId)
        queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
        // We need to refetch the active organization so the `subscription_quantity_limit_reached` on the organization gets updated
        queryClient.invalidateQueries(orgsKeys.activeOrg())
      },
    }
  )
}

// API currently returns an unnecessary level of nesting; this helper function
// can safely be removed once that issue is resolved. See: https://minuthq.atlassian.net/browse/PD-2497
function getHomeFromResponse(responseData: { home: IHome } | IHome): IHome {
  function isCorrectApi(data: IHome | { home: IHome }): data is IHome {
    return !(data as { home: IHome }).home
  }
  return isCorrectApi(responseData) ? responseData : responseData.home
}

interface IFetchHomeMemberResponse extends IBasePaginatedEndpoint {
  members: IHomeMember[]
}
export function useFetchHomeMembers({
  orgId,
  homeId,
  filter,
  options,
}: IFetchHomeQuery & {
  options?: UseQueryOptions<
    IFetchHomeMemberResponse,
    AxiosError,
    IFetchHomeMemberResponse,
    ReturnType<typeof orgsKeys.homeMembers>
  >
}) {
  async function fetchHomeMembers({
    orgId,
    homeId,
  }: IFetchHomeQuery): Promise<IFetchHomeMemberResponse> {
    const response = await minutApiHttpClient.get<IFetchHomeMemberResponse>(
      `${API_ORGS}/${orgId}/homes/${homeId}/members`,
      {
        params: filter,
      }
    )
    return response.data
  }

  return useQuery(
    orgsKeys.homeMembers(orgId, homeId, filter),
    () => fetchHomeMembers({ orgId, homeId }),
    {
      keepPreviousData: true,
      ...options,
      enabled:
        options?.enabled !== undefined
          ? options.enabled && !!orgId && !!homeId
          : !!orgId && !!homeId,
    }
  )
}

interface IDeleteHomeMember {
  orgId: string
  homeId: string
  memberId: string
}
export function useDeleteHomeMember() {
  const queryClient = useQueryClient()

  async function deleteHomeMember({
    orgId,
    homeId,
    memberId,
  }: IDeleteHomeMember) {
    return await minutApiHttpClient.delete(
      `${API_ORGS}/${orgId}/homes/${homeId}/members/${memberId}`
    )
  }

  return useMutation<AxiosResponse, AxiosError, IDeleteHomeMember>(
    deleteHomeMember,
    {
      onSuccess: (_, { orgId, homeId, memberId }) => {
        queryClient.setQueryData<IOrganizationMember[]>(
          orgsKeys.homeMembers(orgId, homeId),
          (membersCache) => {
            return membersCache?.filter((m) => m.member_id !== memberId) ?? []
          }
        )
      },
    }
  )
}

interface IPatchHomeMember {
  orgId: string
  homeId: string
  memberId: string
  body: Partial<IHomeMember>
}
export function usePatchHomeMember() {
  const queryClient = useQueryClient()

  async function patchHomeMember({
    orgId,
    homeId,
    memberId,
    body,
  }: IPatchHomeMember): Promise<IOrganizationMember> {
    const response = await minutApiHttpClient.patch<IOrganizationMember>(
      `${API_ORGS}/${orgId}/homes/${homeId}/members/${memberId}`,
      body
    )
    return response.data
  }

  return useMutation<IOrganizationMember, AxiosError, IPatchHomeMember>(
    patchHomeMember,
    {
      onSuccess: (result, { orgId, homeId, memberId }) => {
        queryClient.setQueryData<IOrganizationMember[]>(
          orgsKeys.homeMembers(orgId, homeId),
          (membersCache) => {
            return (membersCache ?? []).map((m) => {
              if (m.member_id !== memberId) {
                return m
              }
              return result
            })
          }
        )
      },
    }
  )
}

interface IPostHomeMember {
  orgId: string
  homeId: string
  memberId: string
  role: HomeRole
}

export function usePostHomeMember() {
  const queryClient = useQueryClient()

  async function postHomeMember({
    orgId,
    homeId,
    memberId,
    role,
  }: IPostHomeMember): Promise<IOrganizationMember> {
    const response = await minutApiHttpClient.post<IOrganizationMember>(
      `${API_ORGS}/${orgId}/homes/${homeId}/members`,
      {
        member_id: memberId,
        role: role,
      }
    )
    return response.data
  }

  return useMutation<IOrganizationMember, AxiosError, IPostHomeMember>(
    postHomeMember,
    {
      onSuccess: (result, { orgId, homeId, memberId }) => {
        queryClient.setQueryData<IOrganizationMember[]>(
          orgsKeys.homeMembers(orgId, homeId),
          (membersCache) => {
            if (!membersCache) return []
            return [...membersCache, result]
          }
        )
      },
    }
  )
}

interface IPatchIndoorClimateMonitoring {
  homeId: string
  data: Partial<Omit<TIndoorClimateMonitoring, "status">>
}

export function usePatchIndoorClimateMonitoring({ orgId }: { orgId: string }) {
  const { updateCachedHomeDetail, updateCachedHomeList } = useOrgQueryCache()

  async function patchIndoorClimateMonitoring({
    homeId,
    data,
  }: IPatchIndoorClimateMonitoring) {
    const response = await minutApiHttpClient.patch<TIndoorClimateMonitoring>(
      `${API_DEFAULT}/homes/${homeId}/indoor_climate_monitoring`,
      data
    )

    return response.data
  }

  return useMutation({
    mutationFn: patchIndoorClimateMonitoring,
    onSuccess: (indoorClimateMonitoring, vars) => {
      updateCachedHomeDetail(orgId, vars.homeId, (home) => ({
        ...home,
        indoor_climate_monitoring: indoorClimateMonitoring,
      }))

      updateCachedHomeList(orgId, (homes) => {
        return homes.map((home) => {
          if (home.home_id === vars.homeId) {
            return {
              ...home,
              indoor_climate_monitoring: indoorClimateMonitoring,
            }
          } else {
            return home
          }
        })
      })
    },
  })
}
